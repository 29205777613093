import React from 'react';
import { Outlet } from 'react-router-dom';

import Nav from '../components/nav';
import { Toaster } from 'react-hot-toast';

const layout = props => {
  return (
    <>
      <Toaster/>
      <div className="container">
        <Nav/>
        <Outlet />
      </div>
    </>
  );
};
export default layout;
